<template>
  <v-row>
    <v-col
      cols="12"
      :class="{ 'mobile': isMobile}"
    >
      <img
        class="misc-mask"
        height="226"
        :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      />
      <v-card>
        <v-card-title>
          <div class="page-title text-center px-1 mx-auto">
            <h2
              class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center"
            >
              <v-icon
                color="warning mr-2"
                class="hidden-sm-and-down"
              >
                {{ icons.mdiGamepadVariantOutline }}
              </v-icon>
              <span class="me-2 ml-2"> FantasyKombat Arcade</span>
            </h2>
          </div>
        </v-card-title>
        <v-card-text class="text-center">
          <p class="text-sm mt-2 mb-4 px-5">
            Practise your Arcade skills and prepare for
            <router-link to="/fight-tournaments">
              "Arcade Tournaments"
            </router-link>
            .<br>
            Arcade Tournaments are knockout competitions against other players with chances to win
            KombatCoins and
            Custom
            NFTs.<br>
            You team
            <router-link to="/fight-tournaments">
              PowerUps
            </router-link>
            such as "speed", "health", "power" affect you in game character.
          </p>

          <v-btn-toggle>
            <v-btn
              x-small
              color="primary"
              @click="miniscreen"
            >
              <v-icon class="mr-1 hidden-sm-and-down">
                {{ icons.mdiGamepadVariantOutline }}
              </v-icon>
              Mini <span class="hidden-sm-and-down ml-1 mr-1"> Arcade </span> Mode
            </v-btn>
            <v-btn
              disabled
              x-small
              color="primary"
            >
              <v-icon class="mr-1 hidden-sm-and-down">
                {{ icons.mdiFullscreen }}
              </v-icon>
              Normal <span class="hidden-sm-and-down ml-1">Mode</span> (Larger <span
                class="hidden-sm-and-down ml-1"
              > Screen</span>)
            </v-btn>
          </v-btn-toggle>
        </v-card-text>

        <div
          v-if="!isMobile"
          class="bg mt-8 pt-1"
        >
        </div>
        <div
          id="mainArcade"
          class="arcade-container"
          @click="loadArcade"
        >
          <iframe
            id="arcadeIframe"
            width="100%"
            height="400"
            border="0"
            :src="arcade"
          >
          </iframe>
        </div>
        <div class="text text-center">
          <v-chip
            outlined
            @click="pause"
          >
            <v-icon v-if="isPaused">
              {{ icons.mdiPauseCircle }}
            </v-icon>
            <v-icon v-if="!isPaused">
              {{ icons.mdiPlayCircle }}
            </v-icon>
            <span class="ml-1">Pause</span>
          </v-chip>
          <v-chip
            outlined
            class="ml-2"
            @click="mute"
          >
            <v-icon v-if="isMuted">
              {{ icons.mdiVolumeOff }}
            </v-icon>
            <v-icon v-if="!isMuted">
              {{ icons.mdiVolumeHigh }}
            </v-icon>
            <span class="ml-1">Mute</span>
          </v-chip>
        </div>
        <v-card-text>
          <div
            id="contols"
            class="text-left ml-4 mt-2 pt-5 hidden-sm-and-down"
          >
            <br>
            <Strong>Controls:</Strong>
            <v-chip-group>
              <v-chip>Walk, crouch, jump: <strong>Arrow keys</strong></v-chip>
              <v-chip>Light punch: <strong>A</strong></v-chip>
              <v-chip>Medium punch: <strong>S</strong></v-chip>
              <v-chip>Hard punch: <strong>D</strong></v-chip>
              <v-chip>Light kick: <strong>Z</strong></v-chip>
              <v-chip>Medium kick: <strong>X</strong></v-chip>
              <v-chip>Hard kick: <strong>C</strong></v-chip>
            </v-chip-group>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiGamepadVariantOutline,
  mdiHandCoinOutline,
  mdiFullscreen,
  mdiPauseCircle,
  mdiPlayCircle,
  mdiVolumeOff,
  mdiVolumeHigh,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiGamepadVariantOutline,
        mdiHandCoinOutline,
        mdiFullscreen,
        mdiPauseCircle,
        mdiPlayCircle,
        mdiVolumeOff,
        mdiVolumeHigh,
      },
      snackbarText: '',
      snackbarType: 'success',
      snackbarTimeout: 5000,
      snackbarShow: false,
      snackbarIcon: '',

    }
  },
  data() {
    return {
      isMobile: false,
      isMuted: false,
      isPaused: false,
      foundCoin: false,
      msg: {},
      iframeID: '',
      arcade: '/arcade/index.html',
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, {passive: true})
  },
  mounted() {
    this.loadedIframe()
    this.onResize()
    window.$crisp?.push(['set', 'session:event', [[['arcade', {status: 'started'}, 'blue']]]])
  },
  methods: {

    // todo refactor lots of vanilla js to use better use vue

    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    miniscreen() {
      if ('orientation' in window.screen) {
        window.screen.orientation.lock('landscape')
      }
      this.$router.push('/fight-arcade')
    },
    loadArcade() {
      document.getElementById('mainArcade').classList.remove('off')
      document.getElementById('mainArcade').classList.add('on')
      if (this.iframeID) {
        this.onResize()
        this.iframeID.focus()
        this.iframeID.click()
      }
    },
    message(newMsg) {
      this.msg = {cmd: newMsg}
      this.iframeID.contentWindow.postMessage(this.msg, window.location.href)
    },
    insertCoin() {
      if (!this.unplugged) {
        document.getElementById('mainArcade').classList.add('on')
        document.getElementById('mainArcade').classList.remove('off')
        this.message('insertCoin()')
        this.mute()
        if (this.iframeID) {
          this.iframeID.focus()
          this.iframeID.click()
        }
      }
    },
    loadedIframe() {
      this.iframeID = document.getElementById('arcadeIframe')
      const handleLoad = () => {
        console.log('loaded')
        document.getElementById('mainArcade').classList.add('on')
        this.iframeID.focus()
        this.iframeID.click()
        if (!this.isMobile) {
          this.message('mobilescreen()')
        }
      }
      this.iframeID.addEventListener('load', handleLoad, true)
    },
    mute() {
      if (this.isMuted) {
        this.message('soundManager_.unmuteAll()')
        this.isMuted = false
      } else {
        this.message('soundManager_.muteAll()')
        this.isMuted = true
      }
    },
    pause() {
      if (this.isPaused) {
        this.message('game_.resume()')
        this.isPaused = false
      } else {
        this.message('game_.pause()')
        this.isPaused = true
      }
    },
  },
}

</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';

#arcadeIframe {
  border: none;
}

.mobile {
  overflow: hidden;
}

.mobile #mainArcade {
  transform: scale(0.95) !important;
  margin-top: -160px;
}

#arcadeExtras.unplugged .plug::before {
  margin-top: 155px
}

#arcadeExtras.unplugged .wire {
  transform: rotateX(180deg) !important;
}

div#mmaStage {
  transform: scale(0.32) !important;
}

div#joymove {
  transform: skewX(-45deg) translateY(-35px);
  transform-origin: bottom;
}

div#joymove .joystick {
  transform: rotate(0deg);
  transform-origin: bottom;
}

div#arcadeScreen.off #arcadeIframe {
  background: #222 !important;
}

div#arcadeScreen.screen-container.reboot {
  background: #222 url('/arcade/images/misc/misc/dos-boot.gif');
  background-repeat: no-repeat;
  background-size: 248px 189px !important;
  opacity: 1;
}

#mainArcade {
  transform: scale(1.1);
}

div#arcadeExtras {
  left: -22px;
}

#mainArcade.on .speakers.on {
  width: 2px;
  animation-direction: alternate;
  animation: width 0.5s infinite;
  opacity: 0.4
}

#mainArcade.off #arcadeSpeakers.speakers {
  width: 2px;
  animation-direction: unset;
  animation: none;
  opacity: 0
}

@keyframes width {
  100% {
    width: -1px;
    opacity: 0.44
  }
  80% {
    width: 2px;
    opacity: 0.5
  }
  70% {
    width: 3px;
    opacity: 0.4
  }
  60% {
    width: -2px;
    opacity: 0.4
  }
  30% {
    width: 3px;
    opacity: 0.5
  }
}

div#joymove .joystick.joyani {
  transform: rotate(0deg);
  transform-origin: bottom;
  -webkit-animation: spin 1s linear 2;
  -moz-animation: spin 1s linear 2;
  animation: spin 1s linear 2;
  animation-direction: alternate;
}

div#joymove .joystick.joyanileft {
  transform: rotate(-20deg);
  transform-origin: bottom;
  -webkit-animation: spin 1s linear;
  -moz-animation: spin 1s linear;
  animation: spin 1s linear;
  animation-direction: alternate;
}

div#joymove .joystick.joyaniright {
  transform: rotate(20deg);
  transform-origin: bottom;
  -webkit-animation: spin 1s linear;
  -moz-animation: spin 1s linear;
  animation: spin 1s linear;
  animation-direction: alternate;
}

img.misc-mask {
  position: absolute;
  bottom: -2px;
}

div#muteDiv {
  cursor: grab;
  width: 77px;
  height: 59px;
}

span#arcadePause {
  margin-top: -82px;
  cursor: grab;
}

div#mainArcade.off div#joybuttons .button {
  background-color: #ddd;
}

.holes {
  font-size: 63px;
  color: #264042;
  margin: -24px 9px -2px 10px;
}

.joytop, .joybottom {
  border: 1px solid red;
}

.joyleft {
  height: 46px;
  position: absolute;
  border-color: yellow;
  width: 56px;
  top: -37px;
  transform: skewX(-40deg);
}

.joyright {
  height: 50px;
  position: absolute;
  left: 75px;
  width: 60px;
  transform: skewX(-35deg);
  top: -42px;
}
</style>
